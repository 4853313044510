<template>
    <div class="container auth-page">
        <div class="d-flex justify-content-center h-100">
            <div class="card">
                <div class="card-header">
                    <h3>Đặt lại mật khẩu</h3>
                </div>
                <div class="card-body">
                    <div class="input-group form-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-key"></i></span>
                        </div>
                        <b-form-input v-model="form.newpass" type="password" class="form-control" placeholder="Nhập mật khẩu mới" />
                    </div>
                    <div class="input-group form-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-key"></i></span>
                        </div>
                        <b-form-input v-model="form.repeatnewpass" type="password" class="form-control" placeholder="Nhập lại mật khẩu" />
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="errors.newpass || errors.repeatnewpass" >
                            <div class="alert alert-danger">
                                <span v-for="error in errors" :key="error[0]">{{error[0]}}<br/></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <b-button :disabled="loading" variant="primary" pill class="btn-block" @click="reset">
                            <b-spinner v-if="loading" small></b-spinner>
                            <span v-else>Đặt lại mật khẩu</span>
                        </b-button>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <a href="/#/login">Quay lại đăng nhập</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css">
    @import '../../assets/css/auth.css';
</style>

<script>
    export default {
        props: ['token'],
        data: function () {
            return {
                form: {},
                errors: {},
                loading: false
            };
        },
        methods: {
            reset: function (evt) {
                evt.preventDefault();
                this.loading = true;
                this.form.token = this.token;
                this.errors = {};
                this.$service.post('/site/reset', this.form).then(response => {
                    if (response.data) {
                        this.$swal({
                            text: 'Đặt lại mật khẩu thành công! Xin mời đăng nhập bằng mật khẩu mới!',
                            icon: 'success'
                        }).then(() => {
                            location.href = '#/login';
                        });
                    } else {
                        this.$swal({
                            text: 'Có lỗi xảy ra! Đặt lại mật khẩu thất bại!',
                            icon: 'error'
                        }).then(() => {
                            location.href = '#/forgot';
                        });
                    }
                }).catch(error => {
                    this.errors = error.response.data;
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    };
</script>